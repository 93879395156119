<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import RadioButtons from '~/components/form-elements/RadioButtons.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useExporting } from '~/composables/exporting'
import { useProduct } from '~/composables/product'
import { useSharing } from '~/composables/sharing'
import constants from '~/constants'
import AuthScope from '~/enums/auth-scope'
import Export from '~/enums/export'
import RecommendationSupplementIntakeTime from '~/enums/recommendation-supplement-intake-time'
import RecommendationSupplementIntakeTimeType from '~/enums/recommendation-supplement-intake-time-type'
import RecommendationTab from '~/enums/recommendation-tab'
import UserPermission from '~/enums/user-permission'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'
import { useAuthStore } from '~/store/auth'
import { useUserStore } from '~/store/user'
import type { RadioButtonChoice } from '~/types/form-elements'

const model = defineModel<boolean>()

const props = defineProps<{
  recommendationId: string
}>()

const messages = {
  failedToAddProductsToCart: 'Не удалось добавить товары в корзину.',
  failedToGetRecommendation: 'Не удалось получить рекомендации.',
  productsAreAddedToCart: 'Товары добавлены в корзину.'
}

const { $apiHelper, $toast } = useNuxtApp()
const authStore = useAuthStore()
const { exportData } = useExporting()
const { createGuestIfNeeded } = useProduct()
const { copyLink } = useSharing()
const userStore = useUserStore()

const currentSupplementIndex = ref(0)
const currentTab = ref(RecommendationTab.Recommendations)
const isAddingProductsToCart = ref(false)
const isDataLoading = ref(false)
const isDataLoadingError = ref(false)
const isRecommendationSupplementsDownloading = ref(false)
const recommendationData = ref<any>(null)

const attributes = computed(() => recommendationData.value.attributes)
const attributes1 = computed(() => [
  { name: 'Итоги консультации', value: attributes.value.consultationResults },
  { name: 'Общие рекомендации', value: attributes.value.generalRecommendations },
  { name: 'Рекомендованная физическая активность', value: attributes.value.recommendedPhysicalActivity },
  { name: 'Рекомендованные анализы и\xA0обследования', value: attributes.value.recommendedTestsAndExaminations },
  { name: 'Рекомендованные консультации', value: attributes.value.recommendedConsultations },
  { name: 'Дополнительные рекомендации', value: attributes.value.additionalRecommendations }
])
const attributes2 = computed(() => [
  { name: 'План питания', value: attributes.value.dietName },
  { name: 'Общие рекомендации по\xA0питанию', value: attributes.value.generalDietaryRecommendations },
  { name: 'Разрешённые продукты и\xA0напитки', value: attributes.value.allowedFoodsAndDrinks },
  { name: 'Исключённые продукты и\xA0напитки', value: attributes.value.notAllowedFoodsAndDrinks },
  { name: 'Кол-во приёмов пищи в\xA0день', value: attributes.value.numberOfMeals },
  { name: 'Последний приём пищи', value: attributes.value.lastMealTime },
  { name: 'Длительность диеты', value: attributes.value.dietDuration },
  { name: 'Дневное потребление воды', value: attributes.value.dailyWaterIntake },
  { name: 'Комментарий', value: attributes.value.commentOnDiet }
])
const attributes3 = computed(() => {
  const supplementData = supplementsData.value[currentSupplementIndex.value]
  const { attributes } = supplementData

  return [
    { name: 'Рекомендации по\xA0приёму', value: attributes.recommendationsForIntake },
    { name: 'Совместимость', value: attributes.compatibleSupplements },
    { name: 'Несовместимость', value: attributes.incompatibleSupplements },
    { name: 'Разовая дозировка', value: attributes.dosage },
    { name: 'Кол-во приёмов в\xA0день', value: attributes.numberOfIntakes },
    { name: 'Длительность приёма', value: attributes.intakeDuration },
    {
      name: 'Время приёма',
      value: attributes.intakeTimeIds.map(
        (x: RecommendationSupplementIntakeTime, i: number) => `${RecommendationSupplementIntakeTime.getName(x)} (${RecommendationSupplementIntakeTimeType.getName(attributes.intakeTimeTypeIds[i]).toLowerCase()})`
      ).join('\n')
    }
  ]
})
const authorPromoCodes = computed(() => {
  const { meta } = recommendationData.value

  return meta && Array.isArray(meta.authorPromoCodes) ? meta.authorPromoCodes : []
})
const createdAt = computed(() => recommendationData.value.attributes.createdAt)
const supplementNameChoices = computed(
  () => supplementsData.value.map(
    (x: any, i: number): RadioButtonChoice => ({ labelText: x.attributes.name, value: i }) // TODO: Check the status.
  )
)
const supplementsData = computed(() => recommendationData.value.relationships.supplements.data)
const productIds = computed(
  () => supplementsData
    .value
    .filter((x: any): boolean => typeof x.attributes.productId === 'string')
    .map((x: any): string => x.attributes.productId)
)
const updatedAt = computed(() => recommendationData.value.attributes.updatedAt)

onMounted(loadData)

async function addProductsToCart (): Promise<void> {
  isAddingProductsToCart.value = true

  const { failedToAddProductsToCart, productsAreAddedToCart } = messages
  const authorPromoCode = authorPromoCodes.value[0] ?? null

  try {
    if (!authStore.isUser) {
      await createGuestIfNeeded()
    }

    await $apiHelper.cartProducts.createCartProductsByProductIds([...new Set(productIds.value as string[])])

    if (typeof authorPromoCode === 'string') {
      useCookie('promoCode', constants.cookieOptions).value = authorPromoCode
    }

    $toast.success(productsAreAddedToCart)
  } catch (error) {
    console.error(error)

    $toast.error(failedToAddProductsToCart)
  }

  isAddingProductsToCart.value = false
}

function close (): void {
  model.value = false
}

function copyLinkToRecommendation (): void {
  copyLink(`${constants.siteUrl}?action=viewRecommendation&recommendationId=${encodeURI(props.recommendationId)}`)
}

async function downloadRecommendationSupplements (): Promise<void> {
  isRecommendationSupplementsDownloading.value = true

  const { recommendationId } = props

  await exportData(AuthScope.Client, Export.RecommendationSupplements, 'Схема приёма.xlsx', { recommendationId })

  isRecommendationSupplementsDownloading.value = false
}

async function loadData (): Promise<void> {
  isDataLoading.value = true
  isDataLoadingError.value = false

  try {
    const response = await $apiHelper.recommendations.getRecommendation(props.recommendationId)
    const { data } = JsonApiHelper.denormalizeResponse(response) as any
    recommendationData.value = data
  } catch (error) {
    console.error(error)

    isDataLoadingError.value = true

    $toast.error(messages.failedToGetRecommendation)
  }

  isDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large">
    <template #title>
      Просмотр рекомендаций
    </template>

    <Preloader v-if="isDataLoading" />
    <Notification v-else-if="isDataLoadingError" status="warning">
      {{ messages.failedToGetRecommendation }}
    </Notification>
    <template v-else-if="recommendationData">
      <p>{{ attributes.name ? attributes.name : 'Название не\xA0указано' }}</p>

      <p>
        Созданы {{ helpers.formatDateTime(createdAt) }} (МСК).

        <template v-if="updatedAt !== createdAt">
          Изменены {{ helpers.formatDateTime(updatedAt) }} (МСК).
        </template>
      </p>

      <hr class="mb-6">

      <div class="buttons-area mb-6 flex-wrap">
        <BasicButton
          color="body-background"
          is-small
          title="Копировать ссылку"
          @click="copyLinkToRecommendation"
        />

        <BasicButton
          v-if="userStore.hasPermission(UserPermission.ClientExportRecommendationSupplements)"
          color="body-background"
          :is-loading="isRecommendationSupplementsDownloading"
          is-small
          title="Скачать схему приёма (XLSX)"
          @click="downloadRecommendationSupplements"
        />
      </div>

      <hr class="mb-6">

      <Tabs class="mb-6">
        <li v-for="x in RecommendationTab" :key="x" :class="{ active: currentTab === x }" @click="currentTab = x">
          {{ x === RecommendationTab.Supplements ? `${x} (${supplementsData.length})` : x }}
        </li>
      </Tabs>

      <div v-show="currentTab === RecommendationTab.Recommendations" class="attributes">
        <dl v-for="{ name, value } in attributes1" :key="name">
          <dt>
            <span>{{ name }}</span>
          </dt>
          <dd>
            <div class="pre-wrap">
              <template v-if="value !== null">
                {{ value }}
              </template>
              <SvgIcon v-else name="outlined/null" />
            </div>
          </dd>
        </dl>
      </div>

      <div v-show="currentTab === RecommendationTab.Diet" class="attributes">
        <dl v-for="{ name, value } in attributes2" :key="name">
          <dt>
            <span>{{ name }}</span>
          </dt>
          <dd>
            <div class="pre-wrap">
              <template v-if="value !== null">
                {{ value }}
              </template>
              <SvgIcon v-else name="outlined/null" />
            </div>
          </dd>
        </dl>
      </div>

      <div v-show="currentTab === RecommendationTab.Supplements">
        <template v-if="supplementsData.length">
          <RadioButtons
            v-model="currentSupplementIndex"
            :choices="supplementNameChoices"
            class="mb-6"
            is-vertical
            label-text="Название"
            name="supplementName"
          />

          <div class="attributes mb-6">
            <dl v-for="{ name, value } in attributes3" :key="name">
              <dt>
                <span>{{ name }}</span>
              </dt>
              <dd>
                <div class="pre-wrap">
                  <template v-if="value !== null">
                    {{ value }}
                  </template>
                  <SvgIcon v-else name="outlined/null" />
                </div>
              </dd>
            </dl>
          </div>

          <BasicButton
            v-if="productIds.length"
            color="green"
            :title="supplementsData.length === 1 ? 'В\xA0корзину' : 'Добавить все товары в\xA0корзину'"
            @click="addProductsToCart"
          />
        </template>
        <Notification v-else status="info">
          Нет рекомендованных БАД и&nbsp;др.
        </Notification>
      </div>

      <hr class="my-6">

      <p class="disclaimer">
        Приведённая в&nbsp;рекомендациях информация является информационной услугой, не&nbsp;является и&nbsp;не&nbsp;может быть принята и&nbsp;понята пользователем и&nbsp;иными лицами как: заключение о&nbsp;диагнозе; рецепт или иной медицинский документ; план лечения, результат оказания услуг лечащим врачом; результат оказания иных услуг, в&nbsp;частности, медицинских услуг. Данная информационная услуга не&nbsp;является медицинской услугой и&nbsp;не&nbsp;может быть понята и&nbsp;принята как таковая в&nbsp;рамках Федерального закона от&nbsp;21.11.2011 №&nbsp;323-ФЗ «Об&nbsp;основах охраны здоровья граждан в&nbsp;Российской Федерации» с&nbsp;именениями и&nbsp;дополнениями. Информационная услуга не&nbsp;исключает необходимости обращения и&nbsp;записи на&nbsp;очный приём к&nbsp;врачу.
      </p>
    </template>

    <template v-if="!isDataLoading" #action>
      <a v-if="isDataLoadingError" href="#" @click.prevent="loadData">Повторить попытку</a>
      <a v-else-if="recommendationData" href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>

<style lang="scss" scoped>
@import 'assets/css/attributes';

.buttons-area {
  display: flex;
  margin-bottom: -8px;

  & > :deep(button) {
    margin-bottom: 8px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.disclaimer {
  font-size: 12px;
  line-height: 1.5em !important;
}
</style>
